<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <validation-observer v-slot="{ handleSubmit }">
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <v-card>
          <v-card-title primary-title />
          <v-card-text>
            <v-row class="mt-12">
              <v-col
                v-for="(value, index) in fields"
                :key="index"
                class="align"
                cols="6"
              >
                <v-row>
                  <v-col
                    v-if="value.type !== 'spacer'"
                    class="text-button align-self-center pt-0 mb-9 "
                    cols="4"
                  >
                    <div>
                      <span class="font-weight-bold">{{
                        $t('messages.' + value.key)
                      }}</span>
                      <span class="red--text">
                        {{
                          value.validate
                            ? value.validate.includes('required')
                              ? '*'
                              : ''
                            : ''
                        }}</span>
                    </div>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <v-textarea
                      v-if="value.type === 'textarea'"
                      v-model="value.value"
                      :placeholder="$t(value.placeholder)"
                      clearable
                      outlined
                    />
                    <validation-provider
                      v-else-if="value.type === 'date-text'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <div v-if="'menu' in value">
                        <v-row>
                          <v-col cols="10">
                            <v-text-field
                              v-model="value.value"
                              :name="value.key"
                              clearable
                              outlined
                              autocomplete="off"
                              :error-messages="errors[0]"
                              :placeholder="$t(value.placeholder)"
                              @keydown="checkDate"
                            >
                              <template v-slot:append />
                            </v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-menu
                              :ref="'menu' + value.key"
                              v-model="value.menu"
                              :close-on-content-click="false"
                              :return-value.sync="value.value"
                              transition="scale-transition"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="mt-4 mr-3"
                                  v-bind="attrs"
                                  left
                                  v-on="on"
                                >
                                  mdi-calendar
                                </v-icon>
                              </template>
                              <v-date-picker
                                v-model="value.value"
                                locale="ja-jn"
                                no-title
                                scrollable
                                :max="value.max_date ? value.max_date : ''"
                              >
                                <v-spacer />
                                <v-btn
                                  text
                                  color="primary"
                                  @click="value.menu = false"
                                >
                                  {{ $t('messages.cancel') }}
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    $refs['menu' + value.key][0].save(
                                      value.value
                                    )
                                  "
                                >
                                  {{ $t('messages.ok') }}
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-else>
                        <v-text-field
                          v-model="value.value"
                          :name="value.key"
                          clearable
                          outlined
                          :error-messages="errors[0]"
                          :placeholder="$t(value.placeholder)"
                          @keydown="checkDate"
                        />
                      </div>
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'select'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <v-select
                        v-if="value.key==='position'"
                        v-model="positionName"
                        :name="value.key"
                        outlined
                        :error-messages="errors[0]"
                        :items="listPositions"
                        :placeholder="$t(value.placeholder)"
                      />
                      <v-select
                        v-else
                        v-model="value.value"
                        :name="value.key"
                        outlined
                        :error-messages="errors[0]"
                        :items="value.items"
                        item-text="text"
                        item-value="value"
                        :placeholder="$t(value.placeholder)"
                      />
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'text'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <v-text-field
                        v-model="value.value"
                        :placeholder="$t(value.placeholder)"
                        :name="value.key"
                        outlined
                        :error-messages="errors[0]"
                        clearable
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!--others-->

            <!--button-->
            <v-row align="center" />
          </v-card-text>
          <v-card-actions />
        </v-card>
        <v-row class="d-flex justify-end mt-3">
          <v-col
            cols="2"
            class="d-flex justify-space-between"
          >
            <v-btn
              large
              block
              color="info"
              type="submit"
            >
              <span class="text-h5 pr-3 pl-3">
                {{ $t('messages.signup') }}</span>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              large
              block
              color="info"
            >
              <span
                class="text-h5 pr-3 pl-3"
                @click="$router.push('/employee-list/')"
              >
                {{ $t('messages.close') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
// Utilities
  import { get } from 'vuex-pathify'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import { required, email, min } from 'vee-validate/dist/rules'
  import moment from 'moment'
  import _ from 'lodash'
  export default {
    name: 'EmployeeAdd',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        rePasswordCheck: '',
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        fields: [
          {
            key: 'position',
            type: 'select',
            validate: 'required',
          },
          {
            key: 'dob',
            value: '',
            type: 'date-text',
            max_date: moment().format('YYYY-MM-DD'),
            validate: 'required|date|rangeDate',
            placeholder: 'placeholder.dob',
            menu: false,
          },
          {
            key: 'name',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            key: 'sex',
            items: [
              { value: 1, text: '男' },
              { value: 0, text: '女' },
            ],
            value: '',
            type: 'select',
            validate: 'required',
          },
          {
            key: 'furiganaName',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            key: 'email',
            value: '',
            type: 'text',
            validate: 'required|email',
          },
          {
            key: 'contactPhone',
            value: '',
            type: 'text',
            validate: 'required|phone',
          },
          {
            key: 'spacer',
            value: '',
            type: 'spacer',
          },
          {
            key: 'contactAddress',
            value: '',
            type: 'textarea',
            placeholder: 'placeholder.contactAddress',
          },
        ],
        positionName: 7,
      }
    },
    computed: {
      ...get('employee', ['message', 'status', 'error', 'newEmployee', 'listPositions']),
    },
    watch: {
      error (value) {
        if (value) {
          this.snackbarMessage = this.$t(value)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },

      message (value) {
        if (value === 'success') {
        }
      },
      newEmployee (value) {
        this.$store.dispatch('employee/getNewPass', value.data.employee.user.password)
        this.$router.push({ path: '/employee-edit/' + value.data.employee.id })
      },
    },
    created () {
      const user = JSON.parse(localStorage.getItem('user'))
      const data = JSON.parse(_.get(user, 'data', {}))
      const type = _.get(data, 'type', 0)

      if (type !== 1) {
        this.$router.push('/')
      }
    },
    mounted () {
      // Define the rule globally
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })

      extend('email', {
        ...email,
        message: this.$t('validator.email_invalid'),
      })

      extend('number', {
        validate (value) {
          return /^\+?(0|[1-9]\d*)$/.test(value)
        },
        message: this.$t('validator.must_be_number'),
      })
      extend('min', {
        ...min,
        message: this.$t('validator.password_invalid'),
      })
      extend('date', {
        validate (value) {
          return /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(value)
        },
        message: this.$t('生年月日は正確な形式で入力してください。'),
      })
      extend('passwordRegex', {
        validate (value) {
          return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value)
        },
        message: '英大文字・英小文字・数字を組み合わせて8文字以上で入力してください',
      })
      extend('password', {
        params: ['target'],
        validate (value, target) {
          if (typeof target === 'object') return value === target[0]
          else if (typeof target === 'string' || typeof target === 'number') {
            return value === target
          }
        },
        message: this.$t('validator.confirm_password_invalid'),
      })
      extend('phone', {
        validate (value) {
          return /^[+]?[(]?[0-9]{4}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,5}$/.test(value)
        },
        message: this.$t('数字のみ入力してください。'),
      })
      extend('rangeDate', {
        validate (value) {
          if (moment(value).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) return false
          return true
        },
        message: this.$t('生年月日は過去の日付である必要があります'),
      })
    },
    methods: {
      onSubmit: _.debounce(
        function () {
          if (this.rePasswordCheck) return
          const payload = {
            name: this.fields.find(o => o.key === 'name').value,
            dob: this.fields.find(o => o.key === 'dob').value,
            sex: this.fields.find(o => o.key === 'sex').value,
            position: this.positionName,
            furiganaName: this.fields.find(o => o.key === 'furiganaName').value,
            email: this.fields.find(o => o.key === 'email').value,
            contactPhone: this.fields.find(o => o.key === 'contactPhone').value,
            contactAddress: this.fields.find(o => o.key === 'contactAddress').value,
            contactInfo: '123',
          }
          this.$store.dispatch('employee/create', payload)
        }, 500),
      hasErrors (errors) {
        return errors.length > 0
      },
      checkDate (evt) {
        const field = this.fields.find(o => o.key === 'dob')
        if (
          (evt.keyCode >= 48 && evt.keyCode <= 57) ||
          (evt.keyCode >= 96 && evt.keyCode <= 105) ||
          evt.keyCode === 231
        ) {
          evt = evt || window.event

          if (field.value) {
            var size = field.value.length
            if (size >= 10) field.value = field.value.slice(0, -1)
            if (
              (size === 4 && field.value > moment().year()) ||
              (size === 7 &&
                (Number(field.value.split('-')[1]) > 12 ||
                  Number(field.value.split('-')[1]) < 1)) ||
              (size === 10 &&
                (Number(field.value.split('-')[2]) > 31 ||
                  Number(field.value.split('-')[1]) < 1))
            ) {
              field.value = ''
              // add alert funct
              return
            }

            if (
              (size === 4 && field.value < moment().year()) ||
              (size === 7 && Number(field.value.split('-')[1]) < 13)
            ) {
              field.value += '-'
            }
          }
        }
      },
    },
  }
</script>
